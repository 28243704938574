import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

const TableBody = ({ items }) => {
  return (
    <tbody>
      {items.map(({ name, use, path }) => (
        <tr>
          <td>{name}</td>
          <td>{use}</td>
          <td><Button href={path} target='_blank' variant='danger'>Descarga <Badge variant='light'>pdf</Badge></Button></td>
        </tr>
      ))}
    </tbody>
  );
}

TableBody.propTypes = {
  items: PropTypes.shape({
    name: PropTypes.string,
    use: PropTypes.string,
    path: PropTypes.string
  }).isRequired
}

export default TableBody;
