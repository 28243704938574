import React, { useState } from 'react';
import Header from './Header';
import Inicio from '../pages/Inicio/';
import Scouts from '../pages/Scouts/';
import Noticias from '../pages/Noticias';
import Recursos from '../pages/Recursos';
import Contacto from '../pages/Contacto';
import Castores from '../pages/Castores';
import Manada from '../pages/Manada';
import Tropa from '../pages/Tropa';
import Esculta from '../pages/Esculta';
import Clan from '../pages/Clan';
import Kraal from '../pages/Kraal';
import Login from '../pages/Login';
import Err404 from '../pages/Err404';
import Footer from './Footer';
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  return (
    <Router>
      <Header loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
      <Routes>
        <Route path="/" exact element={<Inicio loggedIn={loggedIn} />} />
        <Route path="/scouts" exact element={<Scouts loggedIn={loggedIn} />} />
        {/*<Route path="/noticias" exact element={<Noticias loggedIn={loggedIn} />} />*/}
        <Route path="/secciones_castores" exact element={<Castores loggedIn={loggedIn} />} />
        <Route path="/secciones_manada" exact element={<Manada loggedIn={loggedIn} />} />
        <Route path="/secciones_tropa" exact element={<Tropa loggedIn={loggedIn} />} />
        <Route path="/secciones_esculta" exact element={<Esculta loggedIn={loggedIn} />} />
        <Route path="/secciones_clan" exact element={<Clan loggedIn={loggedIn} />} />
        <Route path="/secciones_kraal" exact element={<Kraal loggedIn={loggedIn} />} />
        <Route path="/recursos" exact element={<Recursos loggedIn={loggedIn} />} />
        <Route path="/contacto" exact element={<Contacto loggedIn={loggedIn} />} />
        <Route path="/login" exact element={<Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
        <Route component={Err404} />
      </Routes>
      <Footer loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
      <ScrollUpButton />
    </Router>
  );
}

export default App;
