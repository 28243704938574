import React from 'react';
import Slider from './Slider';

const Banner = ({ type, subtype, sliderItems, imagePath }) => {
  switch (type) {
    case 'slider': return (
      <Slider items={sliderItems} type={subtype} />
    );
    case 'image': return (
      <img src={imagePath} alt='' className='w-100 banner img-fluid'/>
    );
    default: return (<span>No se ha detallado el tipo de banner</span>);
  }
}

export default Banner;
