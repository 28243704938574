import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Form, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';
import Title from '../components/Title'
import Layout from '../components/Layout';
import { database } from '../firebase';

const Login = ({ loggedIn, setLoggedIn }) => {
  const history = useNavigate();
  const [showPassword, setShowPassword] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [users, setUsers] = useState()
  const [errors, setErrors] = useState({
    email: false,
    password: false
  })
  const [info, setInfo] = useState({
    email: '',
    password: ''
  })

  useEffect(() => {
    database.ref('/users').once('value')
      .then((snapshot) => setUsers(snapshot.val()))
  }, [])

  const handleOnChange = ({ target }) => {
    const { id, value } = target
    setInfo({ ...info, [id]: sha256(value).toString() })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const { email, password } = info
    if (users.hasOwnProperty(email.substr(0, 6))) {
      setErrors({ ...errors, ['email']: false })
      if (password.localeCompare(users[email.substr(0, 6)].password) === 0) {
        setShowModal(true)
        setErrors({ ...errors, ['password']: false, ['email']: false })
        setTimeout(() => {
          setLoggedIn(true)
          history.push('/')
        }, 1000)
      } else {
        setErrors({ ...errors, ['password']: true })
      }
    } else {
      setErrors({ ...errors, ['email']: true })
    }
  }
  return (
    <>
      <Helmet>
        <title>· GS. Pléyades 569 | Login ·</title>
      </Helmet>
      <Layout>
        <div style={{ marginTop: '25px', borderRadius: '5px', background: 'rgba(255,0,0,0.8)' }}>
          <Title>Acceso a modo administrador</Title>
        </div>
        <div className="row">
          <div className="col-12">
            <Form className='my-3' noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control onChange={handleOnChange} type="email" placeholder="Introduce tu correo" />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control onChange={handleOnChange} type={showPassword ? 'text' : 'password'} placeholder="Introduce tu contraseña" />
                <Form.Text
                  hidden={!errors.email && !errors.password ? true : false}
                  style={{ color: '#ff3333', fontSize: '13px' }}>
                  El usuario o la contraseña son incorrectos. Por favor, revísalo.
                </Form.Text>
              </Form.Group>
              <Form.Check
                className='my-2'
                label='Mostrar contraseña'
                onClick={() => setShowPassword(!showPassword)} />
              <Button type='submit' variant="primary">
                Entrar
              </Button>
            </Form>
          </div>
        </div>
      </Layout>
      <Modal centered className='d-flex justify-content-center' show={showModal}>
        <Modal.Body>
          <Spinner variant='dark' animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;
