import React from 'react';
import { Button } from 'react-bootstrap';
import Sponsor from './Sponsor';
import Signature from './Signature';
import SocialList from './SocialList';
import './Footer.css'

const socialItems = [
  { icon: 'youtube', url: 'https://www.youtube.com/channel/UCj3FemXJ8zVyrCxG8fjhJug/featured' },
  { icon: 'facebook', url: 'https://www.facebook.com/gruposcout.pleyades/?epa=SEARCH_BOX' },
  { icon: 'instagram', url: 'https://www.instagram.com/gspleyades569/' },
  { icon: 'twitter', url: 'https://twitter.com/GSpleyades569' },
];

const Footer = ({ loggedIn, setLoggedIn }) => {
  return (
    <footer className='mt-3'>
      <div className='container'>
        <div className='row mt-3'>
          <Sponsor className='col-12 col-md-3' />
          <Signature className='col-12 col-md-6' />
          <SocialList className='col-12 col-md-3' items={socialItems} />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
