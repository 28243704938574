import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Image, Spinner, Button } from 'react-bootstrap';
import Markdown from 'markdown-to-jsx';
import { Banner, KraalDisplay, Layout, Section, Title, AdminMode, Icon } from '../../components';
import { database } from '../../firebase';

const Kraal = ({ loggedIn }) => {
	const [ itemPath, setItemPath ] = useState();
	const [ item, setItem ] = useState();
	const [ showModal, setShowModal ] = useState(false);
	const [ scouters, setScouters ] = useState([
		{
			unidad: 'castores'
		},
		{
			unidad: 'manada'
		},
		{
			unidad: 'tropa'
		},
		{
			unidad: 'esculta'
		},
		{
			unidad: 'clan'
		}
	]);

	const [ posts, setPosts ] = useState({
		'que-es-el-kraal': {
			title: '',
			body: ''
		},
		equipos: {
			title: '',
			body: ''
		},
		unete: {
			title: '',
			body: ''
		}
	});

	useEffect(() => {
		database.ref('/pages/kraal/posts').once('value').then((snapshot) => setPosts(snapshot.val()));
	}); 


	return (
		<>
			<Helmet>
				<title>· GS. Pléyades 569 | Kraal ·</title>
			</Helmet>
			<Banner type="image" imagePath="/images/Kraal/kraal-banner.webp" />
			<Layout>
				<div>
					<Title id="que-es-el-kraal">{posts['que-es-el-kraal'].title}</Title>
					<Section className="d-flex align-items-center justify-content-center">
						<div className="col-12">
							<Markdown>{posts['que-es-el-kraal'].body}</Markdown>
						</div>
						<div className="col-12">
							<Image src="/images/Kraal/kraal.webp" rounded fluid className="col-12" />
						</div>
					</Section>
					{loggedIn ? (
						<div className="row">
							<div className="col-12 d-flex justify-content-end">
								<Button
									variant="danger"
									onClick={() => {
										setItem(posts['que-es-el-kraal']);
										setShowModal(true);
										setItemPath('/pages/kraal/posts/que-es-el-kraal');
									}}>
									<Icon className={`icon icon-pencil`} size="18px" icon="pencil" />
								</Button>
							</div>
						</div>
					) : (
						''
					)}
				</div>
				<div>
					<Title id="equipos">{posts['equipos'].title}</Title>
					<Section>
						<Markdown>{posts['equipos'].body}</Markdown>
						<KraalDisplay scouters={scouters} scoutersSetter={setScouters} />
					</Section>
					{loggedIn ? (
						<div className="row">
							<div className="col-12 d-flex justify-content-end">
								<Button
									variant="danger"
									onClick={() => {
										setItem(posts['equipos']);
										setShowModal(true);
										setItemPath('/pages/kraal/posts/equipos');
									}}>
									<Icon className={`icon icon-pencil`} size="18px" icon="pencil" />
								</Button>
							</div>
						</div>
					) : (
						''
					)}
				</div>
				<div>
					<Title>{posts['unete'].title}</Title>
					<Section>
						<Markdown>{posts['unete'].body}</Markdown>
					</Section>
					{loggedIn ? (
						<div className="row">
							<div className="col-12 d-flex justify-content-end">
								<Button
									variant="danger"
									onClick={() => {
										setItem(posts['unete']);
										setShowModal(true);
										setItemPath('/pages/kraal/posts/unete');
									}}>
									<Icon className={`icon icon-pencil`} size="18px" icon="pencil" />
								</Button>
							</div>
						</div>
					) : (
						''
					)}
				</div>
			</Layout>
			<AdminMode.EditPost
				showModal={showModal}
				setShowModal={setShowModal}
				itemPath={itemPath}
				item={item}
				type="text"
			/>
		</>
	);
};

export default Kraal;
