import React from 'react';
import PropTypes from 'prop-types';
import SocialListItem from './SocialListItem';

const SocialList = ({ className, items }) => {
  return (
    <ul id='social' className={`${className} d-flex justify-content-between align-items-center`}>
      {items.map(({ icon, url }, i) => (
        <SocialListItem key={icon} icon={icon} url={url} />
      ))}
    </ul>
  );
}

SocialList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(SocialListItem.propTypes)),
}

SocialList.defaultProps = {
  items: [],
}

export default SocialList;
