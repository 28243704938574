import React from 'react';
import PropTypes from 'prop-types';

const LinkedImage = ({ url, imagePath }) => {
  return (
    <a href={url} target='_blank' rel='noopener noreferrer'>
      <img src={imagePath} className='img-fluid' alt='' />
    </a>
  );
}

LinkedImage.propTypes = {
  url: PropTypes.string.isRequired,
  imagePath: PropTypes.string
}

LinkedImage.defaultProps = {
  url: '',
  imagePath: ''
}

export default LinkedImage;
