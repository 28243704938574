import React, { useState } from 'react'
import ReactMde from 'react-mde'
import 'react-mde/lib/styles/css/react-mde-all.css'

const MarkdownEditor = ({ oldValue, setValue }) => {
    return(
    <ReactMde
    l18n={{write: 'Pléyades 569⚜️'}}
    onTabChange={()=>{console.log('')}}
    value={oldValue}
    onChange={setValue} />
  )
}

export default MarkdownEditor
