import React from 'react';
import PropTypes from 'prop-types';

const TableHead = ({ items }) => {
  return (
    <thead className='thead-dark'>
      <tr>
        {items.map(({ name }) => (
          <th className='text-center'>
            {name}
          </th>
        ))}
      </tr>
    </thead>
  );
}

TableHead.propTypes = {
  items: PropTypes.shape({
    name: PropTypes.string
  }).isRequired
}


export default TableHead;
