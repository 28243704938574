import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TableHead from './TableHead';
import TableBody from './TableBody';

const CustomTable = ({ tHeads, tBody }) => {
  return (
    <Table size='sm' responsive bordered hover>
      <TableHead items={tHeads} />
      <TableBody items={tBody} />
    </Table>
  );
}

CustomTable.propTypes = {
  tHeads: PropTypes.shape(TableHead.propTypes),
  tBody: PropTypes.shape(TableBody.propTypes)
}

CustomTable.defaultProps = {
  tHeads: {},
  tBody: {}
}

export default CustomTable;
