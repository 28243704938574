import React from 'react';

const Styles = {
  border: 'solid 1.5px smooth',
  borderRadius: '5px',
  background: 'rgba(2,30,79,0.9)',
  color: 'white',
}

const Title = ({ children, id }) => {
  return (
  <h4 className='text-center my-2 py-2' id={id} style={Styles}>{children.toUpperCase()}</h4>
  );
}

export default Title;
