import React from 'react';

const Err404 = () => {
  return (
    <div className='col-12 d-flex justify-content-end align-items-center' style={{ backgroundImage: 'url("/images/404/404.jpg")', height: '677px' }}>
      <p className='col-8' >
      No hemos podido encontrar la página que has solicitado. Prueba a volver atrás o utiliza la barra de navegación para ir a la página de inicio.
      <br/>
      Si aun así ves que sigue sin funcionar y necesitas cualquier tipo de información, <a href="mailto:pleyades569@gmail.com">no dudes en enviarnos un correo.</a><br/>
    
      <i class="ml-5 pl-5"><strong class="ml-5 pl-4">“Una dificultad deja de serlo tan pronto como sonrías ante ella y la afrontes.”</strong></i><br/> Robert Baden-Powell
      </p>
    </div>
  );
}

export default Err404;
