import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const BasicInput = ({ id, label, type, placeholder, feedback, onChangeHandler, conditions }) => {
  return (
    <Form.Group className={`${conditions[0] > 0 || conditions[1] > 0 ? 'was-validated' : ''}`} controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        onChange={onChangeHandler}
        type={type}
        placeholder={placeholder}
        controlId={id}
        required />
      <Form.Control.Feedback type='invalid'>{feedback}</Form.Control.Feedback>
    </Form.Group>
  );
}

BasicInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  feedback: PropTypes.string,
  onChangeHandler: PropTypes.func
}

BasicInput.defaultProps = {
  id: '',
  label: '',
  type: '',
  placeholder: '',
  feedback: '',
  onChangeHandler: null
}

export default BasicInput;
