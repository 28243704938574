import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';


const EventCalendar = ({ language }) => {

  const buttons = { today: 'today' };
  if (language === 'es') {
    buttons.today = 'hoy'
  }
  // Los calendarios están creados en el Google Calendar de Comunicación
  const defaultEvents = [
    { googleCalendarId: '1fb172ec9e4976e925f9369c4a58663771075a0f24f8a8890cf4cdc20f6e3fd2@group.calendar.google.com', color: '#021e4f', name: 'general' },  //Eventos de Grupo
    { googleCalendarId: '5e7084a0c34ce515b4b079bf3515699248ac2e6a8a0fa8d2446901efbd574d85@group.calendar.google.com', color: '#2ec4b5', name: 'castores' }, //Eventos de Castores
    { googleCalendarId: '9b2c07b4e8fabd899212c892010d54d0314a8fbc38f5092f49a19f8721925b98@group.calendar.google.com', color: '#c9be27', name: 'manada' },   //Eventos de Manada
    { googleCalendarId: '61577d858fe877c4e0ca109365f8a3d3f0f3253921c898758fc97d5c11d37ea0@group.calendar.google.com', color: '#61bd49', name: 'tropa' },    //Eventos de Tropa
    { googleCalendarId: 'b6e339bcc52b706fbb3c5dc365912badfb4ca630938d6e9ac141a2f9ca4d62dc@group.calendar.google.com', color: '#94420c', name: 'esculta' },  //Eventos de Esculta
    { googleCalendarId: 'c3dfd610114fb9b3dd2671fd79fcd6a0d26de9c81ceb106b2136938674958680@group.calendar.google.com', color: '#ad1002', name: 'clan' }      //Eventos de Clan
  ]

  let events = [...defaultEvents]
  return (
    <div className='col-12 w-100' style={{ background: '#fff', padding: '10px', borderRadius: '5px' }}>
      <FullCalendar
        plugins={[dayGridPlugin, googleCalendarPlugin]}
        googleCalendarApiKey='AIzaSyBG3rkysChZH94fl3J9jbe8EJjLf5eLTzo'
        height='auto'
        initialView="dayGridMonth"
        showNonCurrentDates={false}
        headerToolbar={{ start: 'title', center: '', end: 'today' }}
        footerToolbar={{ start: 'prev', end: 'next' }}
        firstDay={1}
        buttonText={buttons}
        locale={language}
        fixedWeekCount={false}
        eventSources={events}
      />
    </div>
  );
}

export default EventCalendar;
