import { element } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Modal, Button, Alert, Form, ProgressBar } from 'react-bootstrap'
import { database, storage } from '../../../firebase'
import './EditFile.css'

const EditFile = ({ showModal, setShowModal, itemPath, type }) => {

  const [selected, setSelected] = useState()
  const [editing, setEditing] = useState(false)
  const [fileToEdit, setFileToEdit] = useState(null)
  const [newFile, setNewFile] = useState(null)
  const [files, setFiles] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [error, setError] = useState(null)

  const handleChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setNewFile(file)
    }
  }

  const handleUploadImage = () => {
    const uploadTask = storage.ref(itemPath).child(fileToEdit.name).put(newFile)
    uploadTask.on('state_changed',
      (snapshot) => { },
      (error) => {
        setError(true)
        setShowAlert(true)
      },
      () => {
        storage.ref(itemPath).child(fileToEdit.name).getDownloadURL()
          .then((url) => {
            database.ref(itemPath).child(selected).set({
              name: fileToEdit.name,
              size: fileToEdit.size,
              url: fileToEdit.url
            })
          })
        setShowAlert(true)
      })
  }

  useEffect(() => {
    if (showModal) {
      if (itemPath !== undefined) {
        database.ref(itemPath).once('value')
          .then((snapshot) => setFiles(snapshot.val()))
      }
    }
  }, [itemPath])

  switch (type) {
    case 'image':
      return (
        <>
          <div className='d-flex justify-content-center'>
            {error ?
              <Alert
                className='text-center'
                style={{ position: 'fixed', top: '10px', width: '98%', zIndex: '9999' }}
                variant='danger'
                show={showAlert}>Ha ocurrido un error. Prueba más tarde.</Alert> :
              <Alert
                className='text-center'
                style={{ position: 'fixed', top: '10px', width: '98%', zIndex: '9999' }}
                variant='success'
                show={showAlert}>Editado correctamente</Alert>}
          </div>
          <Modal
            size='lg'
            show={showModal}>
            <Modal.Title className='text-center mt-3'>Modo edición</Modal.Title>
            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <p>Selecciona la imagen que quieres cambiar: </p>
                  <div>
                    {files === null && showModal ? '' : files.map((file, index) => (
                      <li id={`element`} onClick={() => {
                        setSelected(index)
                        setFileToEdit(file)
                        setEditing(true)
                        console.log(fileToEdit)
                      }} style={{ background: `${selected === index ? 'rgba(0, 0, 0, 0.2)' : ''}` }} className='mt-2 d-flex justify-content-center align-items-center'>
                        <img width='300px' src={file.url} alt={file.name} />
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {editing ?
                    <>
                      <hr />
                      <div className='d-flex justify-content-center'>
                        <div className='col-12'>
                          <Form.File onChange={handleChange} lang='es' custom label='Selecciona la imagen con la que quieres sustituirla:' />
                          <p style={{ fontSize: '12px' }} className='text-muted'>El tamaño recomendado es <strong>{fileToEdit.size}</strong>. <span className='text-danger'>Asegúrate de que cumple las dimensiones recomendadas.</span></p>
                        </div>
                      </div>
                      {newFile !== null ?
                        <>
                          <p>
                            {newFile.name}
                          </p>
                          <p style={{ fontSize: '12px' }} className='text-muted'>El nombre de la imagen se cambiará a <strong>{fileToEdit.name}</strong></p>
                        </> : ''}
                    </> : null}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
              <Button
                onClick={() => setShowModal(false)}
                variant='outline-danger'>
                Cancelar
                </Button>
              <Button
                disabled={newFile === null}
                onClick={() => {
                  handleUploadImage()
                  setShowAlert(true)
                  setTimeout(() => {
                    setShowAlert(false)
                  }, 3000)
                  setShowModal(false)
                }}
                variant='success'>
                Guardar
                </Button>
            </Modal.Footer>
          </Modal>
        </>
      )
      break
    case 'file':
      return (
        <>
          <div className='d-flex justify-content-center'>
            {error ?
              <Alert
                className='text-center'
                style={{ position: 'fixed', top: '10px', width: '98%', zIndex: '9999' }}
                variant='danger'
                show={showAlert}>Ha ocurrido un error. Prueba más tarde.</Alert> :
              <Alert
                className='text-center'
                style={{ position: 'fixed', top: '10px', width: '98%', zIndex: '9999' }}
                variant='success'
                show={showAlert}>Editado correctamente</Alert>}
          </div>
          <Modal
            size='xl'
            show={showModal}>
            <Modal.Title className='text-center mt-3'>Modo edición</Modal.Title>
            <Modal.Body>
              Archivos
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
              <Button
                onClick={() => setShowModal({ ...showModal, ['file']: false })}
                variant='outline-danger'>
                Cancelar
                </Button>
              <Button
                onClick={() => {
                  setShowModal({ ...showModal, ['file']: false })
                  setTimeout(() => {
                    setShowAlert(false)
                  }, 3000)
                }}
                variant='success'>
                Guardar
                </Button>
            </Modal.Footer>
          </Modal>
        </>
      )
      break
  }
}

export default EditFile
