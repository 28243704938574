import React from 'react';

const CustomQuote = ({ title, children }) => {
  return (
    <dl style={{ background: 'rgba(0,0,0,0.05)', padding: '5px', borderRadius: '5px' }} className='my-3'>
      <dt>{title}</dt>
      <dd>"{children}"</dd>
    </dl>
  );
}

export default CustomQuote;
