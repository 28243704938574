import React, { useState, useEffect } from 'react';
import moment from 'moment';
import NewsPost from './NewsPost'
import {database} from '../../../firebase';

moment.updateLocale('es', {
  months: [
    "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio",
    "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ]
});

const NewsItem = ({ year }) => {
  const [posts, setPosts] = useState([
    { date: '', text: '' }
  ]);

  useEffect(() => {
    database.ref(`/pages/news/daily/${year}/posts`).once('value')
      .then((snapshot) => setPosts(snapshot.val()))
  }, [year])
  return (
    <>
      {posts === null ? '' : posts.sort((a, b) => moment(a.date).isBefore(moment(b.date))).map(({ title, date, text }, index) => (
        <NewsPost
          title={title}
          post={text}
          publishDate={date}
          position={index % 2}
          lastPost={index + 1 === posts.length} />
      ))}
    </>
  )
}

export default NewsItem;
