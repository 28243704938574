import React from 'react';

const Signature = ({ className }) => {
  return (
    <div className={`${className} align-self-start align-items-center`}>
      <br />
      <p className='text-center align-self-center'>· 2019 - 2023 | Grupo Scout Pléyades 569 ·</p>
      <br />
    </div>
  );
}

export default Signature;
