import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Badge } from 'react-bootstrap';


const BookListItem = ({ title, author, overview, imagePath, filePath }) => {
  return(
    <li className='mb-3'>
          <Card>
            <Card.Body>
              <div className='row'>
                <div className='col-12 col-md-4'>
                  <img className='img-fluid' src={imagePath} style={{border: 'solid 0.5px #000'}} alt='' />
                </div>
                <div className="col-12 col-md-8 d-flex">
                  <div className='d-flex align-content-around flex-wrap'>
                    <div className="w-100" style={{ background: '#dbd6d3', padding: '2px 0 2px 0', borderRadius: '5px' }}>
                      <Card.Title className='my-3 text-center'><strong>{title}</strong></Card.Title>
                      <Card.Subtitle className='mb-3 text-muted text-center'>{author}</Card.Subtitle>
                    </div>
                    <Card.Text>{overview}</Card.Text>
                    <Button href={filePath} target='_blank' variant='danger' block>Descarga <Badge variant='light'>pdf</Badge></Button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </li>

  );
}

BookListItem.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
  overview: PropTypes.string,
  filePath: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired
}

BookListItem.defaultProps = {
  title: '',
  author: 'Desconocido',
  overview: '',
  filePath: '',
  imagePath: ''
}

export default BookListItem;
