import React, { useState, useEffect } from 'react';
import { Image, Modal, Button } from 'react-bootstrap';
import Markdown from 'markdown-to-jsx';

const ScouterDisplay = ({ nombre, unidad, descripcion }) => {
  const [show, setShow] = useState(false);
  const [color, setColor] = useState('#fff')

  useEffect(() => {
    switch (unidad) {
      case 'castores':
        setColor('#2ec4b5')
        break
      case 'manada':
        setColor('#c9be27')
        break
      case 'tropa':
        setColor('#61bd49')
        break
      case 'esculta':
        setColor('#94420c')
        break
      case 'clan':
        setColor('#ad1002')
        break
      default:
        setColor('#fff')
    }
  }, [color])

  return (
    <>
      <Image
        as='button'
        //onClick={() => setShow(true)}
        src={`/images/Kraal/${nombre.toLowerCase()}.webp`}
        style={{ border: `solid ${color} 2px` }}
        width='150px'
        fluid
        roundedCircle />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Title as='h4' className='text-center mt-2'>{nombre}</Modal.Title>
        <Modal.Body>
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <Image src={`/images/Kraal/${nombre.toLowerCase()}-modal.webp`} fluid rounded />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <Markdown>{descripcion}</Markdown>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <Button variant="danger" onClick={() => setShow(false)}>
                Cerrar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ScouterDisplay;
