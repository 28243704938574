import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Markdown from 'markdown-to-jsx'
import { Banner, Layout, Title, Section, CustomQuote, AdminMode, Icon } from '../../components'
import {database} from '../../firebase'

const Esculta = ({ loggedIn }) => {

  const [itemPath, setItemPath] = useState()
  const [item, setItem] = useState()
  const [showModal, setShowModal] = useState(false)
  const [posts, setPosts] = useState({
    'premisa': {
      body: ''
    },
    'simbolos': {
      body: ''
    },
    'ambientacion': {
      body: ''
    }
  })

  useEffect(() => {
    database.ref('/pages/vikings/posts').once('value')
      .then((snapshot) => setPosts(snapshot.val()))
  })

  return (
    <>
      <Helmet>
        <title>· GS. Pléyades 569 | Esculta ·</title>
      </Helmet>
      <Banner type='image' imagePath='/images/Esculta/esculta-banner.webp' />
      <Layout>
        <Title>LA ESCULTA ROSA DE LOS VIENTOS</Title>
        <div>
          <Section>
            <div className="row">
              <div className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                <img src="/images/Esculta/esculta-1.webp" style={{ borderRadius: '5px' }} className='img-fluid' alt="" />
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-center align-items-center">
                <div>
                  <CustomQuote title='Lema'>
                    Escultas: <span style={{ color: '#94420c', fontWeight: 'bold' }}>¡unidad!</span>
                  </CustomQuote>
                  <Markdown>{posts["premisa"].body}</Markdown>
                </div>
              </div>
            </div>
          </Section>
          {loggedIn ? <div className='row'><div className='col-12 d-flex justify-content-end'><Button variant='danger' onClick={() => {
            setItem(posts['premisa'])
            setShowModal(true)
            setItemPath('/pages/vikings/posts/premisa')
          }}><Icon className={`icon icon-pencil`} size='18px' icon='pencil' /></Button></div></div> : ''}
        </div>
        <div>
          <Section>
            <div className="row">
              <div className="col-12 col-md-7 d-flex justify-content-center align-items-center">
                <Markdown>{posts.simbolos.body}</Markdown>
              </div>
              <div className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                <img src="/images/Esculta/esculta-2.webp" style={{ borderRadius: '5px' }} className='img-fluid' alt="" />
              </div>
            </div>
          </Section>
          {loggedIn ? <div className='row'><div className='col-12 d-flex justify-content-end'><Button variant='danger' onClick={() => {
            setItem(posts['simbolos'])
            setShowModal(true)
            setItemPath('/pages/vikings/posts/simbolos')
          }}><Icon className={`icon icon-pencil`} size='18px' icon='pencil' /></Button></div></div> : ''}
        </div>
        <div>
          <Section>
            <div className="row">
              <div className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                <img src="/images/Esculta/esculta-3.webp" style={{ borderRadius: '5px' }} className='img-fluid' alt="" />
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-center align-items-center">
                <Markdown>{posts.ambientacion.body}</Markdown>
              </div>
            </div>
          </Section>
          {loggedIn ? <div className='row'><div className='col-12 d-flex justify-content-end'><Button variant='danger' onClick={() => {
            setItem(posts['ambientacion'])
            setShowModal(true)
            setItemPath('/pages/vikings/posts/ambientacion')
          }}><Icon className={`icon icon-pencil`} size='18px' icon='pencil' /></Button></div></div> : ''}
        </div>
      </Layout>
      <AdminMode.EditPost
        showModal={showModal}
        setShowModal={setShowModal}
        itemPath={itemPath}
        item={item}
        type='text' />
    </>
  )
}

export default Esculta
