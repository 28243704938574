import React, { useState, useEffect } from 'react';
import { Form, Button, FormCheck, Alert } from 'react-bootstrap';
import moment from 'moment';
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
import BasicInput from './BasicInput';
import BasicTextArea from './BasicTextArea';
import SelectList from './SelectList';
import './InfoForm.css';

const year = moment().year();

const options = [
  { name: `${year - 5} o posterior`, value: year - 5 },
  { name: `${year - 6}`, value: year - 6 },
  { name: `${year - 7}`, value: year - 7 },
  { name: `${year - 8}`, value: year - 8 },
  { name: `${year - 9}`, value: year - 9 },
  { name: `${year - 10}`, value: year - 10 },
  { name: `${year - 11}`, value: year - 11 },
  { name: `${year - 12}`, value: year - 12 },
  { name: `${year - 13}`, value: year - 13 },
  { name: `${year - 14}`, value: year - 14 },
  { name: `${year - 15}`, value: year - 15 },
  { name: `${year - 16}`, value: year - 16 },
  { name: `${year - 17}`, value: year - 17 },
  { name: `${year - 18}`, value: year - 18 },
  { name: `${year - 19}`, value: year - 19 },
  { name: `${year - 20}`, value: year - 20 },
  { name: `${year - 21} o anterior`, value: year - 21 }
];

const InfoForm = () => {

  const [visible, setVisible] = useState(false)
  const [info, setInfo] = useState({ nombre: '', correo: '', persona1: '', persona2: '', persona3: '', persona4: '', mensaje: '' })
  const [checked, setChecked] = useState(false)
  const [verified, setVerified] = useState(false)
  const [submitCount, setSubmitCount] = useState(0)

  let recaptchaInstance;

  const handleOnChange = ({ target }) => {
    const { id, value } = target
    setInfo({ ...info, [id]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitCount(1 + submitCount)
    console.log('Validando formulario...\n\n')

    const form = e.currentTarget;
    if (form.checkValidity() === false || !checked) {
      console.log(`Formulario inválido. Intento fallido número ${submitCount + 1}`)
    } else {
      console.log('Formulario válido. Realizando solicitud...')
      if (!verified) {
        console.log('No eres humano')
      } else {
        const sendedInfo = { ...info }
        setVisible(true)
        setChecked(false)
        setInfo({ nombre: '', correo: '', persona1: '', persona2: '', persona3: '', persona4: '', mensaje: '' })
        setSubmitCount(0)
        e.target.reset()

        recaptchaInstance.reset()
        
        await axios.post('/api/send_email', { sendedInfo })
        .then(({ data }) => { console.log(data) })
      }
    }
  }

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false)
      }, 3000);
    }
  })

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <h5><strong>Sobre usted</strong></h5>
      <Form.Group className='mx-3' controlId='sobre-usted'>
        <BasicInput
          id='nombre'
          type='text'
          label='Nombre'
          placeholder='Introduzca su nombre'
          feedback='Por favor, introduzca su nombre para saber a quién dirigirnos.'
          onChangeHandler={handleOnChange}
          conditions={[info.nombre.length, submitCount]} />
        <BasicInput
          id='correo'
          type='email'
          label='Correo electrónico'
          placeholder='Introduzca su correo electrónico'
          feedback='Para poder enviar una solicitud de información, por favor, introduzca un correo electrónico válido.'
          onChangeHandler={handleOnChange}
          conditions={[info.correo.length, submitCount]} />
        <Form.Text className="text-muted">
          Esta información <strong>en ningún caso</strong> será compartida.
        </Form.Text>
      </Form.Group>
      <h5><strong>Sobre la persona (o personas) que tiene(n) interés en entrar en el grupo</strong></h5>
      <Form.Group className='mx-3' controlId='sobre-persona'>
        <SelectList options={options} onChange={handleOnChange} />
      </Form.Group>
      <h5><strong>Si considera que necesita aportar más datos, ¡no dude en dejárnoslos!</strong></h5>
      <Form.Group className='mx-3' controlId='comentario'>
        <BasicTextArea onChange={handleOnChange}></BasicTextArea>
      </Form.Group>
      <Form.Check className='col-12' children={
        <FormCheck className='mb-3'>
          <FormCheck.Input type='checkbox' onClick={() => setChecked(!checked)} />
          <FormCheck.Label className={`small text-${!checked && submitCount > 0 ? 'danger' : 'muted'}`}>Acepto que el <strong>Grupo Scout Pléyades 569</strong> se ponga en contacto conmigo, vía correo electrónico, para proporcionarme la información solicitada.</FormCheck.Label>
        </FormCheck>
      }
        required
        feedbackTooltip={false}
        feedback='Marque esta casilla, si desea enviar la solicitud' />
      <div className="row">
        <div className="col-12 d-flex d-md-block justify-content-center mt-2 mb-3">
          <Recaptcha
            hl='es'
            sitekey='6Le3RrwZAAAAAPUQMY04FmdbHeAvDF800kwrGFVN'
            render='explicit'
            ref={(e) => recaptchaInstance = e}
            onloadCallback={() => console.log('recaptcha cargado')}
            verifyCallback={(response) => { if (response) { setVerified(true) } }} />
        </div>
      </div>
      <Button type='submit' disabled={!verified}>Enviar</Button>
      <Alert
        className='text-center'
        style={{ position: 'fixed', top: '10px', left: '0', width: '100%', zIndex: '9999' }}
        show={visible}
        variant='success' >Solicitud de información enviada correctamente</Alert>
    </Form>
  );
}

export default InfoForm;
