import React from 'react';
import UnitDisplay from './UnitDisplay';

const KraalDisplay = ({ scouters }) => {
  return (
    <ul className='col-12' style={{ listStyle: 'none' }}>
      {scouters.map(({ unidad }, index) => (
        <li key={`seccion-${index}`}>
          <hr />
          <h5
            className='text-center'
            style={{ fontWeight: 'bold' }}>
            {`${unidad.toUpperCase()}`}
          </h5>
          <hr />
          <UnitDisplay unidad={unidad} />
        </li>
      ))}
    </ul>
  );
}

export default KraalDisplay;
