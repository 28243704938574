import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Markdown from 'markdown-to-jsx'
import ReactPlayer from 'react-player'
import { Banner, Layout, Title, Section, AdminMode, Icon } from '../../components'
import { database } from '../../firebase'

const Inicio = ({ loggedIn }) => {

  const [itemPath, setItemPath] = useState()
  const [item, setItem] = useState()
  const [type, setType] = useState('image')
  const [showModalPost, setShowModalPost] = useState(false)
  const [sliderItems, setSliderItems] = useState([
    { name: 'placeholder.jpg', url: '/images/Inicio/slider-item-1.webp' },
    { name: 'placeholder.jpg', url: '/images/Inicio/slider-item-2.webp' },
    { name: 'placeholder.jpg', url: '/images/Inicio/slider-item-3.webp' },
    { name: 'placeholder.jpg', url: '/images/Inicio/slider-item-4.webp' },
    { name: 'placeholder.jpg', url: '/images/Inicio/slider-item-5.webp' },
    { name: 'placeholder.jpg', url: '/images/Inicio/slider-item-6.webp' },
    { name: 'placeholder.jpg', url: '/images/Inicio/slider-item-7.webp' },
  ])
  const [posts, setPosts] = useState({
    'el-grupo-scout-pleyades-569': {
      title: '',
      body: ''
    },
    'el-metodo-scout': {
      title: '',
      body: ''
    }
  })

  const firebasePath = '/pages/home'
  const placeholder = {
    name: 'placeholder.jpg', url: '/images/Inicio/slider-item-1.jpg'
  }

  useEffect(() => {
    database.ref(`${firebasePath}/posts`).once('value')
      .then((snapshot) => setPosts(snapshot.val()))
  }, [posts])

  return (
    <>
      <Helmet>
        <title>· GS. Pléyades 569 | Inicio ·</title>
      </Helmet>
      <div>
        <Banner sliderItems={sliderItems} type='slider' subtype='images' />
      </div>
      <Layout>
        <div>
          <Title id='el-grupo-scout-pleyades-569'>{posts['el-grupo-scout-pleyades-569'].title}</Title>
          <Section>
            <Markdown>
              {posts['el-grupo-scout-pleyades-569'].body}
            </Markdown>
          </Section>
          {loggedIn ? <div className='row'><div className='col-12 d-flex justify-content-end'><Button variant='danger' onClick={() => {
            setItem(posts['el-grupo-scout-pleyades-569'])
            setShowModalPost(true)
            setItemPath('/pages/home/posts/el-grupo-scout-pleyades-569')
          }}><Icon className={`icon icon-pencil`} size='18px' icon='pencil' /></Button></div></div> : ''}
        </div>
        <div>
          <Title id='el-metodo-scout'>{posts["el-metodo-scout"].title}</Title>
          <Section>
            <div className="row">
              <div className="col-12 col-lg-7">
                <ReactPlayer width='100%' height='100%' url='https://youtu.be/m4Oq-0s2C20' />
              </div>
              <div className="col-12 col-lg-5">
                <Markdown>
                  {posts['el-metodo-scout'].body}
                </Markdown>
              </div>
            </div>
          </Section>
          {loggedIn ? <div className='row'><div className='col-12 d-flex justify-content-end'><Button variant='danger' onClick={() => {
            setItem(posts['el-metodo-scout'])
            setShowModalPost(true)
            setItemPath('/pages/home/posts/el-metodo-scout')
          }}><Icon className={`icon icon-pencil`} size='18px' icon='pencil' /></Button></div></div> : ''}
        </div>
      </Layout>
      <AdminMode.EditPost
        showModal={showModalPost}
        setShowModal={setShowModalPost}
        itemPath={itemPath}
        item={item} />
    </>
  )
}

export default Inicio
