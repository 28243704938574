import React, { useEffect, useState } from 'react';
import ScouterDisplay from './ScouterDisplay';
import {database} from '../../../firebase';

const UnitDisplay = ({ unidad }) => {
  const [scouters, setScouters] = useState([])

  useEffect(() => {
    database.ref(`/pages/kraal/scouters/${unidad}`).once('value')
      .then((snapshot) => {
        setScouters(snapshot.val())
      })
  }, scouters)

  return (
    <>
      <ul className='col-12 d-none d-md-flex justify-content-around align-items-center' style={{ listStyle: 'none' }}>
        {scouters.map(({ nombre, descripcion }, index) => (
          <li className='mx-5 mx-md-2 my-3' key={`${unidad}-${index}-${nombre}`}>
            <ScouterDisplay
              nombre={nombre}
              unidad={unidad}
              descripcion={descripcion} />
          </li>
        ))}
      </ul>
      <ul className='col-12 d-block d-md-none justify-content-around align-items-center' style={{ listStyle: 'none' }}>
        {scouters.map(({ nombre, descripcion }, index) => (
          <li style={{ margin: 'auto', width:'50%' }} className='my-3' key={`${unidad}-${index}-${nombre}`}>
            <ScouterDisplay
              nombre={nombre}
              unidad={unidad}
              descripcion={descripcion} />
          </li>
        ))}
      </ul>
    </>
  );
}

export default UnitDisplay;
