import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Modal, Button, Alert, InputGroup, Form } from 'react-bootstrap'
import Markdown from 'markdown-to-jsx';
import { Section, MarkdownEditor } from '../..'
import { database } from '../../../firebase';

moment.updateLocale('es', {
  months: [
    "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio",
    "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ]
});

const now = moment().toString()

const AddPost = ({ showModal, setShowModal, item }) => {
  const [path, setPath] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [title, setTitle] = useState('')
  const [date, setDate] = useState(now)
  const [text, setText] = useState('')
  const [error, setError] = useState(null)
  const [posts, setPosts] = useState([
    { date: '', text: '' }
  ])
  const [position, setPosition] = useState(0)

  const updatePost = (post) => {
    database.ref(path).set(post, (error) => {
      if (error) {
        setError(true)
      } else {
        setError(false)
      }
    })
  }

  useEffect(() => {
    
    const year = moment(date).format('yyyy')
    database.ref(`/pages/news/daily/${year}/posts`).once('value')
    .then((snapshot) => setPosts(snapshot.val()))
    .then(()=> setPosition(posts.length))
    .then(
      setPath(`/pages/news/daily/${year}/posts/${position}`)
      )

    if (item !== undefined) {
      setText(item.text)
      setTitle(item.title)
      setDate(item.date)
    }

  }, [item])

  const formattedDate = `${moment(date).format('D')} de ${moment(date).format('MMMM')}`
  return (
    <>
      <div className='d-flex justify-content-center'>
        {error ?
          <Alert
            className='text-center'
            style={{ position: 'fixed', top: '10px', width: '98%', zIndex: '9999' }}
            variant='danger'
            show={showAlert}>Ha ocurrido un error. Prueba más tarde.</Alert> :
          <Alert
            className='text-center'
            style={{ position: 'fixed', top: '10px', width: '98%', zIndex: '9999' }}
            variant='success'
            show={showAlert}>Editado correctamente</Alert>}
      </div>
      <Modal size='xl' show={showModal}>
        <Modal.Title className='text-center mt-3'>Modo edición</Modal.Title>
        <Modal.Body>
        <div className='row'>

      <div className="col-12">
          <Section>
        <div className={`d-flex justify-content-center`}>
          <div>
            <h3>{title === undefined ? '' : title.toUpperCase()}</h3>
            <div className={`d-flex justify-content-center`}>
              <span className={`badge px-1 justify-self-end`} style={{ background: '#021e4f', color: '#fff' }}>
                {formattedDate}
              </span>
            </div>
          </div>
        </div>
        <br />
        <Markdown className='post'>
          {text}
        </Markdown>
      </Section>
      </div>
    </div>
          {title !== undefined ? <div className="row">
            <div className="col-12">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Título:</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  value={title}
                  onChange={({ target }) => {
                    const { value } = target
                    setTitle(value)
                  }} />
              </InputGroup>
            </div>
          </div> : null}
          <div className="row">
            <div className="col-12">
              <MarkdownEditor setValue={setText} oldValue={text} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            onClick={() => setShowModal(false)}
            variant='outline-danger'>
            Cancelar
            </Button>
          <Button
            disabled={item === undefined ? '' : item.text === text && item.title === title}
            onClick={() => {
              let post = {}
              if (title !== undefined) post = { ...post, title }
              if (text !== undefined) post = { ...post, text }
              if (date !== undefined) post = { ...post, date }
              updatePost(post)
              setShowAlert(true)
              setShowModal(false)
              setTimeout(() => {
                setShowAlert(false)
              }, 3000)
            }}
            variant='success'>
            Guardar
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

AddPost.propTypes = {
    itemPath: PropTypes.string
}

AddPost.defaultProps = {
    itemPath: ''
}

export default AddPost
