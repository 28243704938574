import React from 'react';
import Icon from '../../../Icon';
import PropTypes from 'prop-types';

const SocialListItem = ({ icon, url }) => {
  return (
    <li className='mx-4'>
      <a href={url} target='_blank' rel='noopener noreferrer'>
        <Icon className={`icon icon-${icon}`} size='28px' icon={icon} />
      </a>
    </li>
  );
}

SocialListItem.propTypes = {
  icon: PropTypes.string,
  url: PropTypes.string,
}

SocialListItem.defaultProps = {
  icon: '',
  url: '',
}

export default SocialListItem;
