import firebase from 'firebase/compat/app'

const config = {
  apiKey: "AIzaSyBkq7OxfRnXjB6KFlH1AHAusXHZRRcnR0k",
  authDomain: "pleyades569-web.firebaseapp.com",
  databaseURL: "https://pleyades569-web.firebaseio.com",
  projectId: "pleyades569-web",
  storageBucket: "pleyades569-web.appspot.com",
  messagingSenderId: "528455233239",
  appId: "1:528455233239:web:34e8652e862999f924ca92",
  measurementId: "G-3H93X17WGJ"
}

const firebaseApp = firebase.initializeApp(config)

export default firebaseApp