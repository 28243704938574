import React from 'react';

const Styles = {
  background: '#dbd6d3',
  padding: '20px',
  margin: '10px',
  borderRadius: '5px',
  fontSize: '17px',
}

const Section = ({ children }) => {
  return (
    <div style={Styles}>
        {children}
    </div>
  );
}

export default Section;
