import React, { useState, useEffect } from 'react'
import { Modal, Button, Alert, InputGroup, Form } from 'react-bootstrap'
import Markdown from 'markdown-to-jsx';
import { Title, Section, MarkdownEditor } from '../..'
import { database } from '../../../firebase';

const EditPost = ({ showModal, setShowModal, item, itemPath }) => {
  const [showAlert, setShowAlert] = useState(false)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [error, setError] = useState(null)

  const updatePost = (post) => {
    database.ref(itemPath).set(post, (error) => {
      if (error) {
        setError(true)
      } else {
        setError(false)
      }
    })
  }

  useEffect(() => {
    if (item !== undefined) {
      setBody(item.body)
      setTitle(item.title)
    }

  }, [item])

  return (
    <>
      <div className='d-flex justify-content-center'>
        {error ?
          <Alert
            className='text-center'
            style={{ position: 'fixed', top: '10px', width: '98%', zIndex: '9999' }}
            variant='danger'
            show={showAlert}>Ha ocurrido un error. Prueba más tarde.</Alert> :
          <Alert
            className='text-center'
            style={{ position: 'fixed', top: '10px', width: '98%', zIndex: '9999' }}
            variant='success'
            show={showAlert}>Editado correctamente</Alert>}
      </div>
      <Modal size='xl' show={showModal}>
        <Modal.Title className='text-center mt-3'>Modo edición</Modal.Title>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              {title !== undefined ? <Title>{title}</Title> : null}
              <Section>
                <Markdown>
                  {body === undefined ? '' : body}
                </Markdown>
              </Section>
            </div>
          </div>
          {title !== undefined ? <div className="row">
            <div className="col-12">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>Título:</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  value={title}
                  onChange={({ target }) => {
                    const { value } = target
                    setTitle(value)
                  }} />
              </InputGroup>
            </div>
          </div> : null}
          <div className="row">
            <div className="col-12">
              <MarkdownEditor setValue={setBody} oldValue={body} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            onClick={() => setShowModal(false)}
            variant='outline-danger'>
            Cancelar
            </Button>
          <Button
            disabled={item === undefined ? '' : item.body === body && item.title === title}
            onClick={() => {
              let post = {}
              if (title !== undefined) post = { ...post, title }
              if (body !== undefined) post = { ...post, body }
              updatePost(post)
              setShowAlert(true)
              setShowModal(false)
              setTimeout(() => {
                setShowAlert(false)
              }, 3000)
            }}
            variant='success'>
            Guardar
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditPost
