import React from 'react'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
import { Banner, Layout, Title, Section, CustomTable, BooksList, EventCalendar } from '../components'

const documentsHeader = [{ name: 'Documentos' }, { name: '¿Para qué sirve?' }, { name: 'Descarga' }]

let documentsBody = [
  { name: '*Administración de medicamentos', use: 'Autorización para poder administrar medicamentos básicos a los educandos.', path: '/files/administracion-de-medicamentos.pdf' },
  { name: '*Autorización de asistencia', use: 'Documento necesario para poder hacernos cargo de los educandos.', path: '/files/autorizacion-de-asistencia.pdf' },
  { name: 'Estatutos del G.S. Pléyades 569', use: 'Documento que nos define como asociación (Ronda solar 2018-2019).', path: '/files/estatutos-del-gs-pleyades-569.pdf' },
  { name: '*Ficha de tesorería', use: 'Documento para formalizar las cuotas mensuales de socio.', path: '/files/ficha-de-tesoreria.pdf' },
  { name: '*Ficha médica', use: 'Documento para declarar todas las necesidades de corte médico del educando.', path: '/files/ficha-medica.pdf' },
  { name: 'Líneas pedagógicas', use: 'Documento que define las pautas pedagógicas que seguimos.', path: '/files/lineas-pedagogicas.pdf' },
  { name: 'Protocolo de becas', use: 'Documento explicativo del funcionamiento de las becas que concedemos.', path: '/files/protocolo-de-becas.pdf' },
  { name: 'Solicitud de becas', use: 'Documento para solicitar las becas de que disponemos.', path: '/files/solicitud-de-becas.pdf' },
  { name: '*Consentimiento protección de datos', use: 'Documento de consentimiento de protección de datos', path: '/files/consentimiento_proteccion-de-datos.pdf' },
  { name: 'Programas Educativos 2023', use: 'Documento con los PEDUs de todas las unidades', path: '/files/programas-educativos-2023.pdf' },
]

let books = [
  {
    title: 'Escultismo para muchachos',
    author: 'Robert Baden-Powell',
    overview: 'Bill Gates, el astronauta Neil Armstrong, la escritora J.K. Rowling, J.F. Kennedy, Steven Spielberg, David Beckham, Juan Pablo II, Jacques Chirac o los propios reyes de España lo fueron: todos ellos comparten un pasado scout. Respeto por los hombres y la naturaleza, solidaridad, espíritu de servicio, valentía, responsabilidad, generosidad... son valores tan necesarios ahora como hace un siglo para construir una sociedad más justa y sostenible.',
    imagePath: '/images/Recursos/escultismo-para-muchachos.webp',
    filePath: '/files/escultismo-para-muchachos.pdf'
  },
  {
    title: 'El libro de las tierras vírgenes',
    author: 'Rudyard Kipling',
    overview: 'Autor de obras tan famosas como "Kim", "Capitanes intrépidos" o "El hombre que pudo ser rey", Rudyard Kipling (1865-1936), Premio Nobel de Literatura en 1907, supo siempre hacer del relato breve una estructura literaria perfecta. "El libro de las tierras vírgenes" es una recopilación de quince relatos entre los cuales figura la serie que protagoniza Mowgli, y en los que se conjugan de manera admirable su conocimiento de la grandeza y diversidad de la India y una sensibilidad hacia la naturaleza precursora de la de los tiempos actuales.',
    imagePath: '/images/Recursos/el-libro-de-las-tierras-virgenes.png',
    filePath: '/files/el-libro-de-las-tierras-virgenes.pdf'
  },
  {
    title: 'Cancionero',
    author: 'Grupo Scout Pléyades 569',
    overview: 'Canciones varias del grupo scout Pléyades 569.',
    imagePath: '/images/Recursos/cancionero.webp',
    filePath: '/files/cancionero.pdf'
  },
  {
    title: 'La vida en el estanque',
    author: 'Anónimo',
    overview: 'Libro en el que se ambientan los castores, que consta de relatos cortos sobre la vida de los animales junto al Gran Hermano.',
    imagePath: '/images/Recursos/la-vida-en-el-estanque.webp',
    filePath: '/files/la-vida-en-el-estanque.pdf'
  },
  {
    title: 'Roverismo hacia el éxito',
    author: 'Robert Baden-Powell',
    overview: '"Roverismo hacia el éxito", por Lord Baden-Powell, un libro del deporte de la vida, para jóvenes. En esta obra, el fundador del Movimiento Scout nos deja los sentimientos más íntimos de su corazón, los recuerdos de los hechos que más le mpresionaron a lo largo de su vida, los deseos más intensos que experimentó al fundar el "escultismo". En este libro se encuentran algunas de sus más íntimas confidencias.',
    imagePath: '/images/Recursos/roverismo-hacia-el-exito.webp',
    filePath: '/files/roverismo-hacia-el-exito.pdf'
  },
  {
    title: 'Rema tu propia canoa',
    author: 'Robert Baden-Powell',
    overview: 'En 1938, Lord Baden-Powell escribe este libro, que es otro de los que no tratan de lo que llamamos, hoy en día, el Programa Scout, sino que pretende directamente dar consejos para la vida a los muchachos que se encuentran en el Movimiento, sin que se pueda negar que sea también útil a los muchos que no pertenecen al Escultismo. Por otra parte se nota, a todo lo largo del libro, la preocupación que tiene Baden-Powell de que los muchachos estén preparados para servir a su patria en la segunda guerra mundial que a cada momento se ve como algo inminente; sin embargo los Consejos que se dan a este tenor, se pueden leer como la preparación que necesita todo muchacho para cualquier contingencia en la que se pueda encontrar, durante los días de paz. ',
    imagePath: '/images/Recursos/rema-tu-propia-canoa.webp',
    filePath: '/files/rema-tu-propia-canoa.pdf'
  }
]

const Recursos = () => {
  return (
    <>
      <Helmet>
        <title>· GS. Pléyades 569 | Recursos ·</title>
      </Helmet>
      <Banner type='image' imagePath='/images/Recursos/recursos-banner.webp' />
      <Layout>
        <Title id='documentos'>DOCUMENTOS</Title>
        <Section>
          <p>En esta sección, ponemos a vuestra disposición varios documentos:</p>
          <CustomTable tHeads={documentsHeader} tBody={documentsBody} />
          <p><strong><i>*Fichas esenciales para nuestro funcionamiento. Haz clic <a href='/files/fichas-basicas-gs-pleyades569.zip'>aquí</a> para descargarlas todas juntas.</i></strong></p>
        </Section>
        <Title id='libros'>LIBROS</Title>
        <Section>
          <BooksList items={books} />
        </Section>
        <Title id='calendario-de-ronda'>CALENDARIO DE RONDA</Title>
        <Section>
          <EventCalendar language='es' />
        </Section>
        <Title id='aprende-con-el-kraal'>APRENDE CON EL KRAAL</Title>
        <Section>
          <div className="row">
            <div className="my-3 col-12 col-md-6" >
              <ReactPlayer controls={true} width='100%' url='https://www.youtube.com/watch?v=cYJNwXbe_dM&t=1s' />
            </div>
            <div className="my-3 col-12 col-md-6" >
              <ReactPlayer controls={true} width='100%' url='https://www.youtube.com/watch?v=sG363V0P5Vc' />
            </div>
          </div>
          <div className="row">
            <div className="my-3 col-12 col-md-6" >
              <ReactPlayer controls={true} width='100%' url='https://www.youtube.com/watch?v=muUn1tFWmZ4' />
            </div>
            <div className="my-3 col-12 col-md-6" >
              <ReactPlayer controls={true} width='100%' url='https://www.youtube.com/watch?v=8486sA_3TXM' />
            </div>
          </div>
        </Section>
      </Layout>
    </>
  );
}

export default Recursos;
