import React from 'react';
import LinkedImage from '../../LinkedImage';

const Sponsor = ({ className }) => {
  return (
    <div id='sponsor' className={`${className} d-flex justify-content-between`}>
      <LinkedImage imagePath='/images/mail.png' url='https://www.scout.es/' />
    </div>
  );
}

export default Sponsor;
