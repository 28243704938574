import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';


const Header = ({ loggedIn, setLoggedIn }) => {
  const [adminLogged, setAdminLogged] = useState(false)
  useEffect(() => {
    if (loggedIn) setAdminLogged(true)
    else setAdminLogged(false)
  }, [loggedIn])
  return (
    <NavBar adminLogged={adminLogged} setLoggedIn={setLoggedIn} />
  );
}

export default Header;
