import React from 'react'
import './CustomToggle.css'
import { Badge } from 'react-bootstrap';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    style={{ color: '#fff', paddingBottom: '0' }}
    className='toggle'
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }} >{children}&#160;<span style={{fontSize: '12px'}}>&#x25bc;</span></a>
));

export default CustomToggle;
