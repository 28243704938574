import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Dropdown, Button, Modal } from 'react-bootstrap';
import CustomToggle from './CustomToggle';
import './NavBar.css';

const NewBar = ({ adminLogged, setLoggedIn }) => {
  const [activeLink, setActiveLink] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [color, setColor] = useState('#021e4f')

  return (
    <Navbar style={{ background: color }} expand="md" className='d-flex justify-content-around'>
      <div className='col-3 col-md-4'>
        <Navbar.Brand>
          <Link to='/'>
            <img className='d-none d-lg-block img-fluid' src={`/images/white-logo-texted${adminLogged ? '(admin)' : ''}.png`} width="418px" alt='' />
          </Link>
          <Link to='/'>
            <img className='d-none d-md-block d-lg-none img-fluid' src='/images/white-logo.png' width="150px" alt='' />
          </Link>
          <Link to='/'>
            <img className='d-block d-md-none img-fluid' src='/images/white-logo.png' width="50px" alt='' />
          </Link>
        </Navbar.Brand>
      </div>
      <h6 className='d-md-none text-white mt-2 mr-3'><strong>G.S. Pléyades 569</strong></h6>
      <Navbar.Toggle className='col-2' aria-controls="basic-navbar-nav" />
      <div className='col-12 col-md-5'>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav ActiveKey='Inicio' onSelect={(selectedKey) => { setActiveLink(selectedKey) }}>
            <Nav.Item>
              <Nav.Link as={Link} to='/' eventKey='Inicio' className='text-white nav_link mx-1'>
                Inicio
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to='/scouts' eventKey='Scouts' className='text-white nav_link mx-1'>
                Scouts
              </Nav.Link>
            </Nav.Item>
            {/*<Nav.Item>
              <Nav.Link as={Link} to='/noticias' eventKey='Noticias' className='text-white nav_link mx-1'>
                Noticias
              </Nav.Link>
  </Nav.Item>*/}
            <Nav.Item eventKey='Secciones' as={Dropdown} className='nav_link'>
              <Dropdown.Toggle className='nav_link' as={CustomToggle} id="dropdown-basic">
                Secciones
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item id='castores' eventKey='Castores' as={Link} to='/secciones_castores' className='text-center' style={{ padding: '0' }}>Castores</Dropdown.Item>
                <Dropdown.Item id='manada' eventKey='Manada' as={Link} to='/secciones_manada' className='text-center' style={{ padding: '0' }}>Manada</Dropdown.Item>
                <Dropdown.Item id='tropa' eventKey='Tropa' as={Link} to='/secciones_tropa' className='text-center' style={{ padding: '0' }}>Tropa</Dropdown.Item>
                <Dropdown.Item id='esculta' eventKey='Esculta' as={Link} to='/secciones_esculta' className='text-center' style={{ padding: '0' }}>Esculta</Dropdown.Item>
                <Dropdown.Item id='clan' eventKey='Clan' as={Link} to='/secciones_clan' className='text-center' style={{ padding: '0' }}>Clan</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item id='kraal' eventKey='Kraal' as={Link} to='/secciones_kraal' className='text-center' style={{ padding: '0' }}>Kraal</Dropdown.Item>
              </Dropdown.Menu>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to='/recursos' eventKey='Recursos' className='text-white nav_link mx-1'>
                Recursos
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to='/contacto' eventKey='Contacto' className='text-white nav_link mx-1'>
                Contacto
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </div>
      {adminLogged ?
        <Button
          onClick={() => setShowModal(true)}
          variant='outline-danger'>
          Cerrar sesión
        </Button> : ''}
      <Modal show={showModal}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              Estás a punto de cerrar sesión. ¿Estás seguro/a?
            </div>
          </div>
          <div className="row my-1">
            <div className="col-12 d-flex justify-content-between">
              <Button variant='outline-danger' onClick={() => setShowModal(false)}>Volver</Button>
              <Button variant='danger' onClick={() => {
                setLoggedIn(false)
                setShowModal(false)
              }}>Cerrar sesión</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
}

export default NewBar;
