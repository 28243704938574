import React from 'react';
import { Container } from 'react-bootstrap';

const Layout = (props) => {
  return(
    <Container style={props.style}>
      {props.children}
    </Container>
  );
} 

export default Layout;
