import React, { useState, useEffect } from 'react'
import Markdown from 'markdown-to-jsx'
import { Helmet } from 'react-helmet'
import { Button } from 'react-bootstrap'
import { Banner, Layout, Title, Section, InfoForm, AdminMode, Icon } from '../components'
import {database} from '../firebase'

const Contacto = ({ loggedIn }) => {

  const [itemPath, setItemPath] = useState()
  const [item, setItem] = useState()
  const [showModal, setShowModal] = useState(false)
  const [posts, setPosts] = useState({
    'donde-encontrarnos': {
      title: '',
      body: ''
    },

  })

  useEffect(() => {
    database.ref('/pages/contact/posts').once('value')
      .then((snapshot) => setPosts(snapshot.val()))
  }, [posts])

  return (
    <>
      <Helmet>
        <title>· GS. Pléyades 569 | Contacto ·</title>
      </Helmet>
      <Banner type='image' imagePath='/images/Contacto/contacto-banner.webp' />
      <Layout>
        <Title id='necesitas-informacion'>¿NECESITAS INFORMACIÓN?</Title>
        <Section>
        <p>Si necesita cualquier tipo de información, no dude en mandarnos un correo a <a href='mailto:contacto@pleyades569.com'>contacto@pleyades569.com</a></p>
          {/* <p>Introduzca sus datos para enviarnos una solicitud de información y que podamos responderla de la mejor forma posible:</p>
          <InfoForm /> */}
        </Section>
        <Title id='donde-encontrarnos'>{posts['donde-encontrarnos'].title}</Title>
        <Section>
          <div className="row">
            <div className="col-12 col-md-6">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3037.20804154091!2d-3.6995310000000003!3d40.426392!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42288910c0cbdf%3A0x4ed3eb19e101437c!2sC.%20de%20Barcel%C3%B3%2C%202%2C%2028004%20Madrid!5e0!3m2!1ses!2ses!4v1596627124709!5m2!1ses!2ses" width="100%" height="450" frameborder="0" style={{ border: '0' }} title='Mapa' allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
            <div className="col-12 col-md-6">
              <Markdown>
                {posts['donde-encontrarnos'].body}
              </Markdown>
              <div>
                <img className='img-fluid' src='/images/Contacto/ubicacion.webp' alt='' />
              </div>
            </div>
          </div>
        </Section>
        {loggedIn ? <div className='row'><div className='col-12 d-flex justify-content-end'><Button variant='danger' onClick={() => {
          setItem(posts['donde-encontrarnos'])
          setShowModal(true)
          setItemPath('/pages/contact/posts/donde-encontrarnos')
        }}><Icon className={`icon icon-pencil`} size='18px' icon='pencil' /></Button></div></div> : ''}
      </Layout>
      <AdminMode.EditPost
        showModal={showModal}
        setShowModal={setShowModal}
        itemPath={itemPath}
        item={item}
        type= 'text' />
    </>
  );
}

export default Contacto;
