import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import './Slider.css'

const newsStyle = {
  0: {
    background: '#fff34d',
  },
  1: {
    background: '#ff4d65',
  },
  2: {
    background: '#4d7cff'
  }
}

const Slider = ({ controls, type, items }) => {
  switch (type) {
    case 'images': return (
      <Carousel controls={controls}>
        {items.map(({ url }, i) => (
          <Carousel.Item>
            <img key={`${url}${i}`} className='d-block w-100 carousel__item--animated' src={url} alt={`Slide ${i}`} />
          </Carousel.Item>
        ))}
      </Carousel>
    );
    case 'news': return (
      <Carousel controls={!items.length > 1} interval={15000} fade={true}>
        {items.map(({ imagePath, text, date }, i) => (
          <Carousel.Item className='py-5 d-lg-flex' style={newsStyle[i]}>
            <div className='py-5 m-lg-2 col-12 col-lg-5 d-lg-flex justify-content-center'>
              <img className='d-block d-md-none img-fluid align-self-center' style={{ maxHeight: '300px' }} src={imagePath} alt='' />
              <img className='d-none d-md-block img-fluid align-self-center' style={{ maxHeight: '300px' }} src={imagePath} alt='' />
            </div>
            <div className='mt-0 col-12 col-lg-7 d-lg-flex align-items-center'>
              <p>
                <ReactMarkdown source={text} />
                <span className='badge px-1 justify-self-end' style={{ background: '#021e4f', color: '#fff' }}>{date}</span>
              </p>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    );
    default: return (<span>Este slider no tiene ningún tipo definido</span>);
  }
}

Slider.propTypes = {
  controls: PropTypes.bool,
  type: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imagePath: PropTypes.string,
      text: PropTypes.string,
      date: PropTypes.string,
    })
  ).isRequired,
}

Slider.defaultProps = {
  controls: false,
  type: 'images',
}

export default Slider;
