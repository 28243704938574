import React from 'react';
import PropTypes, { shape } from 'prop-types';
import BookListItem from './BookListItem/BookListItem';

const BooksList = ({ items }) => {
  return (
    <ul style={{ listStyle: 'none', padding: '0' }}>
      {items.map((items, index) => (
        <BookListItem
          title={items.title}
          author={items.author}
          overview={items.overview}
          imagePath={items.imagePath}
          filePath={items.filePath} 
          id={`${items.title}${index}`}/>
      ))}
    </ul>
  );
}

BooksList.propTypes = {
  items: PropTypes.arrayOf(shape(BookListItem.propTypes))
}

BooksList.defaultProps = {
  items: []
}

export default BooksList;
