import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Markdown from 'markdown-to-jsx'
import { Banner, Layout, Title, Section, LinkedImage, AdminMode, Icon } from '../../components'
import { database } from '../../firebase'
import './Scouts.css'

const Scouts = ({ loggedIn }) => {

  const [itemPath, setItemPath] = useState()
  const [item, setItem] = useState()
  const [type, setType] = useState('image')
  const [showModalPost, setShowModalPost] = useState(false)
  const [showModalFile, setShowModalFile] = useState(false)
  const [images, setImages] = useState({
    'banner': {},
    'el-movimiento-scout': {},
    'la-ley-scout': {}
  })
  const [posts, setPosts] = useState({
    'el-movimiento-scout': {
      title: '',
      body: ''
    },
    'como-educamos': {
      title: '',
      body: ''
    },
    'la-ley-scout': {
      title: '',
      body: ''
    }
  })

  const firebasePath = '/pages/scouts'

  useEffect(() => {
    database.ref(`${firebasePath}/posts`).once('value')
      .then((snapshot) => setPosts(snapshot.val()))
      .then(
        database.ref(`${firebasePath}/files/`).once('value')
          .then((snapshot) => {
            setImages({
              ['banner']: snapshot.child('images/banner').val()
            })
          })
      )
  }, [posts]);

  return (
    <>
      <Helmet>
        <title>· GS. Pléyades 569 | Scouts ·</title>
      </Helmet>
      <div>
        <Banner type='image' imagePath='/images/Scouts/banner-scout.webp' />
      </div>
      <Layout>
        <div>
          <Title id='el-movimiento-scout'>{posts["el-movimiento-scout"].title}</Title>
          <Section>
            <div className="row justify-content-center align-items-center">
              <div className='d-block d-md-none mb-3 col-12'>
                <LinkedImage url='https://es.wikipedia.org/wiki/Robert_Baden-Powell' imagePath='/images/Scouts/bp-responsive.webp' />
              </div>
              <div className='col-12 col-lg-9'>
                <Markdown className=''>{posts["el-movimiento-scout"].body}</Markdown>
              </div>
              <div className='d-none d-lg-block col-lg-2'>
                <LinkedImage url='https://es.wikipedia.org/wiki/Robert_Baden-Powell' imagePath='/images/Scouts/bp.webp' />
              </div>
            </div>
          </Section>
          {loggedIn ? <div className='row'><div className='col-12 d-flex justify-content-end'><Button variant='danger' onClick={() => {
            setItem(posts['el-movimiento-scout'])
            setShowModalPost(true)
            setItemPath('/pages/scouts/posts/el-movimiento-scout')
          }}><Icon className={`icon icon-pencil`} size='18px' icon='pencil' /></Button></div></div> : ''}
        </div>
        <div>
          <Title id='como-educamos'>{posts["como-educamos"].title}</Title>
          <Section>
            <Markdown>{posts["como-educamos"].body}</Markdown>
          </Section>
          {loggedIn ? <div className='row'><div className='col-12 d-flex justify-content-end'><Button variant='danger' onClick={() => {
            setItem(posts['como-educamos'])
            setShowModalPost(true)
            setItemPath('/pages/scouts/posts/como-educamos')
          }}><Icon className={`icon icon-pencil`} size='18px' icon='pencil' /></Button></div></div> : ''}
        </div>
        <div>
          <Title id='la-ley-scout'>{posts["la-ley-scout"].title}</Title>
          <Section>
            <div className="row align-items-center">
              <div className="col-md-5 col-lg-3 d-none d-sm-block">
                <img style={{ borderRadius: '5px' }} src="/images/Scouts/ley_scout.webp" className='img-fluid' alt="" />
              </div>
              <div className="col-md-7 col-lg-9">
                <Markdown>{posts["la-ley-scout"].body}</Markdown>
              </div>
            </div>
          </Section>
          {loggedIn ? <div className='row'><div className='col-12 d-flex justify-content-end'><Button variant='danger' onClick={() => {
            setItem(posts['la-ley-scout'])
            setShowModalPost(true)
            setItemPath('/pages/scouts/posts/la-ley-scout')
          }}><Icon className={`icon icon-pencil`} size='18px' icon='pencil' /></Button></div></div> : ''}
        </div>
      </Layout>
      <AdminMode.EditPost
        showModal={showModalPost}
        setShowModal={setShowModalPost}
        itemPath={itemPath}
        item={item}
        type='text' />
      <AdminMode.EditFile
        showModal={showModalFile}
        setShowModal={setShowModalFile}
        itemPath={itemPath}
        type={type} />
    </>
  );
}

export default Scouts;
